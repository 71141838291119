import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './FormElementLabel.module.scss';

const FormElementLabel = ({ children }) => {
  if (children === undefined) return null;

  return (
    <div className={styles.FormElementLabel}>{children}</div>
  );
};

FormElementLabel.propTypes = {
  children: PropTypes.node.isRequired
};

export default FormElementLabel;
