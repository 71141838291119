import React from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../../hooks/use-auth';
import Action from '../../Action/Action';

import * as styles from './UserPresentation.module.scss';
import { Routes } from '../../../../../helpers/routes';

const UserPresentation = ({
  name,
  surname,
  discount,
  nextDiscount,
  ordersToNextDiscount
}) => {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout((callback) => {
      window.location = Routes.root;
    });
  };

  return (
    <div className={styles.UserPresentation}>
      <div className={styles.UserPresentation__nameContainer}>
        <div className={styles.UserPresentation__beforeName} />
        <div className={styles.UserPresentation__name}>
          <div>{user.name || name}</div>
          <div>{user.surname || surname}</div>
        </div>
        <div className={styles.UserPresentation__afterName}>
          {/* <div className={styles.UserPresentation__discount}>
            {`${discount}%`}
          </div> */}
        </div>
      </div>

      <Action
        onClick={handleLogout}
        themes={['Link']}
        className={styles.UserPresentation__logout}
      >
        Выход
      </Action>

      <div className={styles.UserPresentation__nextDiscountMessage}>
        {/* {`До скидки ${nextDiscount}% еще ${ordersToNextDiscount} заказов`} */}
      </div>
    </div>
  );
};

UserPresentation.propTypes = {
  name: PropTypes.string,
  surname: PropTypes.string,
  discount: PropTypes.number,
  nextDiscount: PropTypes.number,
  ordersToNextDiscount: PropTypes.number
};

UserPresentation.defaultProps = {
  name: '',
  surname: '',
  discount: 0,
  nextDiscount: 0,
  ordersToNextDiscount: 0
};

export default UserPresentation;
