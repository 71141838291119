import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BaseShellContainer from '../../BaseShellContainer';
import PageSectionHeader from '../../PageSectionHeader/PageSectionHeader';
import Action from '../../Action/Action';

import * as styles from './PressSection.module.scss';

const ITEMS_PER_PAGE = 3;

const PressSection = ({ items }) => {
  const [itemsToShow, setItemsToShow] = useState(ITEMS_PER_PAGE);

  const isShowMoreButton = items.length > itemsToShow;

  const handleShowMore = () => {
    setItemsToShow(itemsToShow + ITEMS_PER_PAGE);
  };

  return (
    <section className={styles.PressSection}>
      <BaseShellContainer>
        <PageSectionHeader>О нас пишут</PageSectionHeader>
        <div className={styles.PressSection__itemsGrid}>
          {items.slice(0, itemsToShow).map(item => (
            <div key={`press_${item.id}`} className={styles.PressSection__item}>
              <div className={styles.PressSection__logoWrap}>
                <img
                  className={styles.PressSection__logo}
                  src={item.logoUrl}
                  alt={item.title}
                />
              </div>
              <div className={styles.PressSection__text}>{item.text}</div>
              <a
                className={styles.PressSection__link}
                href={item.readMoreUrl}
                target='blank'
                rel='noreferrer'
              >
                <span>Читать далее</span>
              </a>
            </div>
          ))}
        </div>
        {isShowMoreButton && (
          <div className={styles.PressSection__more}>
            <Action themes={['Button']} onClick={handleShowMore}>
              Другие СМИ
            </Action>
          </div>
        )}
      </BaseShellContainer>
    </section>
  );
};

PressSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    logoUrl: PropTypes.string,
    readMoreUrl: PropTypes.string
  })).isRequired
};

export default PressSection;
