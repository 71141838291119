import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import FormsRow from '../../../../FormsRow';
import TextInput from '../../../../TextInput';
import DateTextInput from '../../../../DateTextInput';
import Select from '../../../../Select';

import * as styles from './Clarifications.module.scss';
import Action from '../../../../Action';
import { OrderContext } from '../../../../../context/OrderContext';
import FormElementLabel from '../../../../FormElementLabel';
import TimeTextInput from '../../../../TimeTextInput/TimeTextInput ';

const Clarifications = ({ member, onClose }) => {
  const {
    orderId,
    handleUpdateMemberClarifications
  } = useContext(OrderContext);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange'
  });

  const DropDownField = (clarification) => {
    const options = clarification.options.map(option => (
      <option value={option}>{option}</option>
    ));

    return (
      <FormsRow>
        <Select
          labelText={clarification.field_name[0]}
          name='DropDownField'
          {...register(`${clarification.ids[0]}`)}
          // error={errors.sex?.message}
          // value={clarification.answer[0]}
        >
          <option value=''>Выбрать</option>
          {options}
        </Select>
      </FormsRow>
    );
  };

  const TextField = (clarification) => {
    return clarification.field_name.map((field, ind) => (
      <FormsRow>
        <TextInput
          labelText={field}
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(`${clarification.ids[ind]}`)}
          // error={errors.name?.message}
        />
      </FormsRow>
    ));
  };

  const CalendarField = (clarification) => {
    return clarification.field_name.map((field, ind) => (
      <FormsRow>
        <DateTextInput
          labelText={field}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(`${clarification.ids[ind]}`)}
          // error={errors.birthday?.message}
        />
      </FormsRow>
    ));
  };

  const BetweenTimeField = (clarification) => {
    const fields = clarification.options.map((option, ind) => (
      <TimeTextInput
        labelText={option}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(`${clarification.ids[ind]}`)}
        // error={errors.birthday?.message}
      />
    ));

    return (
      <FormsRow>
        <FormElementLabel>{clarification.field_name[0]}</FormElementLabel>
        <div className={styles.Clarifications__betweenTime}>
          {fields}
        </div>
      </FormsRow>
    );
  };

  const onSubmit = (values) => {
    onClose();
    handleUpdateMemberClarifications({ answers: values, order_id: orderId }, (result) => {
      // if (result.status === 'ok') {
      // }
    });
  };

  const initializeValue = () => {
    member.clarifications.forEach((clarification) => {
      if (clarification.type === 'dropdown') {
        setValue(`${clarification.ids[0]}`, clarification.answer[0]);
      }
      if (['text', 'calendar', 'between_time'].includes(clarification.type)) {
        clarification.ids.forEach((id, ind) => {
          setValue(`${id}`, clarification.answer[ind]);
        });
      }
    });
  };

  useEffect(() => {
    initializeValue();
  }, []);

  return (
    <form
      className={styles.Clarifications}
      onSubmit={handleSubmit(data => onSubmit(data))}
      id='clarifications_id'
    >
      {member?.clarifications?.map((clarification) => {
        if (clarification.type === 'dropdown') {
          return DropDownField(clarification);
        }
        if (clarification.type === 'text') {
          return TextField(clarification);
        }
        if (clarification.type === 'calendar') {
          return CalendarField(clarification);
        }
        if (clarification.type === 'between_time') {
          return BetweenTimeField(clarification);
        }
      })}

      <FormsRow>
        <Action
          type='submit'
          themes={['Button', 'Fullwidth']}
        >
          Сохранить
        </Action>
      </FormsRow>
    </form>
  );
};

Clarifications.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default Clarifications;
