const Routes = {
  orders: '/orders',
  account: '/user/personal',
  offers: '/#offers',
  about: '/#about',
  root: '/'
};

exports.Routes = Routes;
// export default Routes;
