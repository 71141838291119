export const isBrowser = typeof window !== 'undefined'
  && typeof window.document !== 'undefined';

export const isMobileDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileUserAgent = /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
  const isMobileWidth = window.innerWidth < 768;
  const isMobileTouch = ('ontouchstart' in document.documentElement && userAgent.match(/mobi/));

  if (isMobileUserAgent) return true;
  if (isMobileWidth) return true;
  if (isMobileTouch) return true;

  return false;
};
