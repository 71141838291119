import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import * as styles from './FormsRow.module.scss';

const FormsRow = ({ children, className }) => (
  <div
    className={cn(styles.FormsRow,
      { [className]: className }
    )}
  >
    {children}
  </div>
);

FormsRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

FormsRow.defaultProps = {
  className: ''
};

export default FormsRow;
