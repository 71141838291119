import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import useAuth from '../../../../hooks/use-auth';

import authSteps from './form-steps';
import FormsRow from '../FormsRow';
import PhoneTextInput from '../PhoneTextInput';
import DigitPasswordTextInput from '../DigitPasswordTextInput';
import PhoneInputControls from './PhoneInputControls';
import Timer from './Timer/Timer';
import Action from '../Action/Action';
import usePopup from '../../../../hooks/use-popup';
import Popup from '../Popup';
import RecallForm, { CALLBACK_TYPE } from '../ContactVariants/RecallForm';

import * as styles from './AuthForm.module.scss';

const FormValues = {
  phone: PropTypes.string,
  password: PropTypes.string
};

const AuthPhoneStepSchema = Yup.object().shape({
  phone: Yup.string()
    .length(15, 'Неверный номер телефона')
    .required('Введите номер телефона')
});

const AuthPasswordStepSchema = AuthPhoneStepSchema.concat(
  Yup.object().shape({
    password: Yup.string()
      .length(4, 'Пароль должен состоять из 4х символов.')
      .required('Введите пароль')
  })
);

const AuthForm = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch
  } = useForm({ defaultValues: FormValues });
  const phone = watch('phone');
  const password = watch('password');

  const {
    registration,
    login,
    resendSms,
    notice: authNotice,
    error: authError
  } = useAuth();

  const [step, setStep] = useState(authSteps.PHONE);
  const [resendPasswordTimeout, setResendPasswordTimeout] = useState(0);
  const [isRecallFormOpen, openRecallForm, closeRecallForm] = usePopup();
  const [gRecaptchaResponse, setGRecaptchaResponse] = useState();
  const [notice, setNotice] = useState();

  const isPhoneStep = step === authSteps.PHONE;
  const isPasswordStep = step === authSteps.PASSWORD;
  const shouldAutoSubmit = isPasswordStep
    && AuthPasswordStepSchema.isValidSync({ phone, password });

  const clearPasswordFormState = () => {
    setValue('password', '');
  };

  const handleChangeCaptcha = (value) => {
    setGRecaptchaResponse(value);
    setNotice(null);
  };

  const handleExpiredCaptcha = () => {
    setGRecaptchaResponse(null);
    setNotice(null);
  };

  const onSubmit = (data) => {
    // console.log('onSubmit', { ...data, 'gRecaptchaResponse': gRecaptchaResponse });
    if (isPhoneStep) {
      if (!gRecaptchaResponse) {
        setNotice('Ты робот');
        return;
      }
      clearPasswordFormState();
      registration({ ...data, 'gRecaptchaResponse': gRecaptchaResponse }, (callback) => {
        // console.log(callback);
        if (callback.isRegistered) {
          setStep(authSteps.PASSWORD);
          setResendPasswordTimeout(callback.user.timeout_to_resend_password);
        } else {
          clearPasswordFormState();
        }
      });
    }

    if (isPasswordStep) {
      if (!shouldAutoSubmit) return;

      login({ ...data, 'gRecaptchaResponse': gRecaptchaResponse }, (callback) => {
        // console.log(callback);
        if (callback.loggedIn) {
          onClose();
          if (callback.return_to) window.location = callback.return_to;
        } else {
          clearPasswordFormState();
        }
      });
    }
  };

  const handleChangePhoneRequest = () => {
    clearPasswordFormState();
    setGRecaptchaResponse(null);
    setNotice(null);
    setStep(authSteps.PHONE);
  };

  const handleResendSms = (e) => {
    e.preventDefault();

    if (isPasswordStep) {
      if (!gRecaptchaResponse) {
        setNotice('Ты робот');
        return;
      }

      resendSms({ phone, 'gRecaptchaResponse': gRecaptchaResponse }, (callback) => {
        // console.log(callback);
        setResendPasswordTimeout(callback.user.timeout_to_resend_password);
      });
    }
  };

  const handleRecallForm = (e) => {
    e.preventDefault();
    if (!gRecaptchaResponse) {
      setNotice('Ты робот');
      return;
    }
    openRecallForm();
  };

  // if (shouldAutoSubmit) {
  //   console.log('shouldAutoSubmit');
  //   handleSubmit(onSubmit)();
  // }

  const resendPasswordTimeoutMessage = (
    <FormsRow>
      <div className={styles.AuthForm__resendPasswordMessage}>
        <span>Выслать пароль повторно можно&nbsp;через&nbsp;</span>
        <Timer
          initialCounter={resendPasswordTimeout}
          onTimeEnd={() => setResendPasswordTimeout(0)}
        />
        <span>&nbsp;сек.</span>
      </div>
    </FormsRow>
  );

  const resendPasswordLink = (
    <FormsRow>
      <Action
        className={styles.AuthForm__resendPasswordMessage}
        to='/'
        disabled={!isPasswordStep}
        onClick={handleResendSms}
      >
        Выслать пароль ещё раз
      </Action>
      &nbsp;&nbsp;&nbsp;
      <Action
        className={styles.AuthForm__resendPasswordMessage}
        to='/'
        disabled={!isPasswordStep}
        onClick={handleRecallForm}
      >
        Не приходит пароль?
      </Action>
    </FormsRow>
  );

  const captchaTag = (
    <div className={styles.AuthForm__captcha}>
      <ReCAPTCHA
        sitekey={process.env.RECAPTCHA_SITE_KEY}
        stoken={process.env.RECAPTCHA_SECRET_KEY}
        onChange={handleChangeCaptcha}
        onExpired={handleExpiredCaptcha}
      />
    </div>
  );

  return (
    <>
      <form className={styles.AuthForm} onSubmit={handleSubmit(onSubmit)}>
        <h3 className={styles.AuthForm__header}>
          Вход в Личный кабинет
        </h3>

        <FormsRow>
          <PhoneTextInput
            labelText='Номер телефона'
            disabled={!isPhoneStep}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('phone')}
            afterContent={
              (
                <PhoneInputControls
                  step={step}
                  isPhoneValid={AuthPhoneStepSchema.isValidSync({ phone })}
                  onChangePhoneRequest={() => handleChangePhoneRequest()}
                />
              )
            }
          />
          <div className={styles.AuthForm__policyHint}>
            Согласен с обработкой персональных данных
          </div>
        </FormsRow>

        {isPasswordStep && (
          <>
            <FormsRow>
              <DigitPasswordTextInput
                labelText='Пароль 4 цифры'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('password')}
              />
            </FormsRow>

            {resendPasswordTimeout > 0 ? resendPasswordTimeoutMessage : resendPasswordLink}

            {shouldAutoSubmit && (
              <FormsRow>
                <Action
                  type='submit'
                  themes={['Button', 'Fullwidth']}
                  disabled={!isPasswordStep}
                  onClick={handleSubmit(onSubmit)}
                >
                  Войти
                </Action>
              </FormsRow>
            )}
          </>
        )}
        {isPasswordStep && authNotice && <div>{authNotice}</div>}
        {isPasswordStep && authError && <div className={styles.AuthForm__error}>{authError}</div>}
        {notice && <div className={styles.AuthForm__error}>{notice}</div>}
        {captchaTag}
      </form>

      <Popup
        show={isRecallFormOpen}
        onClose={closeRecallForm}
        escapeClose={false}
        outsideClose={false}
        variant='small'
      >
        <RecallForm
          onClose={closeRecallForm}
          recallPhone={phone}
          callBackType={CALLBACK_TYPE.smsNotSend}
        />
      </Popup>
    </>
  );
};

AuthForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default AuthForm;
