import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';
import Action from '../Action/Action';
import { OrderProvider, OrderContext } from '../../context/OrderContext';
import { numberToMoney } from '../../../../helpers/currency';
import Popup from '../Popup';
import usePopup from '../../../../hooks/use-popup';
import PaymentForm from '../OrdersPage/PaymentForm';

import CalendarIcon from '../../../../../icons/calendar-new.svg';
import PeronsIcon from '../../../../../icons/persons.svg';
import AnnuledIcon from '../../../../../icons/circle-red.svg';
import ConfirmedIcon from '../../../../../icons/circle-green.svg';
import WaitingIcon from '../../../../../icons/circle-grey.svg';

import * as styles from './OrderCard.module.scss';

const Icon = [
  WaitingIcon,
  ConfirmedIcon,
  AnnuledIcon,
  AnnuledIcon,
  AnnuledIcon
];

const OrderCardComponent = ({ order }) => {
  const { isLoading } = useContext(OrderContext);
  const [isPaymentOpened, openPayment, closePayment] = usePopup();
  const currentNotPaid = (order?.total_cost || 0) - (order?.amount_paid || 0);

  const handlePayment = () => {
    openPayment();
  };

  return (
    <OrderProvider id={order.id}>
      <div className={styles.OrderCard}>
        <div className={styles.OrderCard__head}>
          <div className={styles.OrderCard__pnr}>
            {`№ ${order.pnr}`}
          </div>
          <div className={styles.OrderCard__status}>
            {order.status}
            <IconSvg icon={Icon[order.status_cd]} />
          </div>
        </div>
        <div className={styles.OrderCard__info}>
          <div className={styles.OrderCard__name}>
            {order.offer_name}
          </div>
          <div className={styles.OrderCard__wrap}>
            <div className={styles.OrderCard__spot}>
              {order.spot}
            </div>
            <div className={styles.OrderCard__icon}>
              <IconSvg icon={PeronsIcon} className={styles.OrderCard__iconCalendar} />
            </div>
            <div className={styles.OrderCard__wrap__text}>
              {order.members_count}
            </div>
          </div>
        </div>
        <div className={styles.OrderCard__datePersons}>
          <div className={styles.OrderCard__wrap}>
            <div className={styles.OrderCard__icon}>
              <IconSvg icon={CalendarIcon} className={styles.OrderCard__iconCalendar} />
            </div>
            <div className={styles.OrderCard__wrap__text}>
              <span>{order.period_to_s}</span>
              <span>{order.duration_to_s}</span>
            </div>
          </div>
        </div>
        <div className={styles.OrderCard__action}>
          <Action
            href={`${order.url}`}
            themes={['Button', 'ButtonSmall']}
          >
            Редактировать заказ
          </Action>
          {/* <Action
            onClick={isLoading ? null : handlePayment}
            themes={['Link']}
          >
            <div className={styles.OrderCard__status}>
              {`К оплате ${numberToMoney(currentNotPaid)}`}
            </div>
          </Action> */}
          <div className={styles.OrderCard__status}>
            {`К оплате ${numberToMoney(currentNotPaid)}`}
          </div>
        </div>
      </div>

      <Popup
        show={isPaymentOpened}
        onClose={closePayment}
        escapeClose={false}
        outsideClose={false}
        variant='small'
      >
        <PaymentForm />
      </Popup>
    </OrderProvider>
  );
};

OrderCardComponent.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

OrderCardComponent.defaultProps = {
};

export default OrderCardComponent;
