import React from 'react';
import PropTypes from 'prop-types';

import OrderCard from '../OrderCard';

import * as styles from './OrdersList.module.scss';

const OrdersList = ({ orders }) => {
  return (
    <div className={styles.OrdersList}>
      {orders.map(order => (
        <OrderCard key={`order-card__${order.id}`} order={order} />
      ))}
    </div>
  );
};

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })).isRequired
};

export default OrdersList;
