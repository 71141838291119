import headersWithToken from '../helpers/header';

const create = async (formData, callback) => {
  const data = {
    'callback_user': formData
  };

  try {
    const response = await fetch('/callback_users', {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

const handleRecall = (formData, result) => {
  create(formData, (callback) => {
    result(callback);
  });
};

export default handleRecall;
