
import { handleLogout } from '../../../services/auth';

const ClickBtnLogout = (e) => {
  e.preventDefault();

  handleLogout((result) => {
    console.log('old', result);
    window.location = '/';
  });
};

const InitBtnLogout = () => {
  const btn = document.querySelector('.js_button-exit__click');

  if (btn) {
    btn.removeEventListener('click', ClickBtnLogout, false);
    btn.addEventListener('click', ClickBtnLogout, false);
    console.log('InitBtnLogout');
  }
};

export default InitBtnLogout;
