import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { OrderContext } from '../../../../../context/OrderContext';
import Checkbox from '../../../../Checkbox';
import IconSvg from '../../../../../../../common/javascript/components/IconSvg/IconSvg';
import SubOrderPeopleSelect from '../../SubOrderPeopleSelect';

import Popup from '../../../../Popup';
import usePopup from '../../../../../../../hooks/use-popup';
import { numberToMoneyWithoutCurrency } from '../../../../../../../helpers/currency';

import ServicesAddIcon from '../../../../../../../../icons/services-add.svg';
import BecomePartnerIcon from '../../../../../../../../icons/partner2.svg';

import * as styles from './SubOrder.module.scss';
import Action from '../../../../Action';
import Clarifications from '../../Members/Clarifications/Clarifications';

export const TYPE = {
  ServiceSelect: 'ServiceSelect',
  Service: 'Service'
};

const SubOrder = ({ product, type }) => {
  const {
    isLoading,
    addMemberToSubOrder
  } = useContext(OrderContext);
  const [isPeopleSelect, openPeopleSelect, closePeopleSelect] = usePopup();
  const [isClarification, openClarification, closeClarification] = usePopup();
  const [subOrder, setSubOrder] = useState({});
  const [currentMember, setCurrentMember] = useState({});
  const [isCalculate, setIsCalculate] = useState(false);
  const [error, setError] = useState('');

  const handleCheckSubOrder = (subOrderPeopleSelect, memberId) => {
    setError('');
    setIsCalculate(true);
    addMemberToSubOrder(subOrderPeopleSelect, memberId, (result) => {
      console.log(result);
      if (result.status === 'ok') setIsCalculate(false);
      if (result.status === 'error') {
        setError(result.error);
        setIsCalculate(false);
      }
    });
  };

  const handlePopupSubOrder = (subOrderPeopleSelect) => {
    setSubOrder(subOrderPeopleSelect);
    openPeopleSelect();
  };

  const handleClosePeopleSelect = (checkedItems) => {
    setIsCalculate(true);
    closePeopleSelect();
    addMemberToSubOrder(subOrder, checkedItems, (result) => {
      console.log(result);
      if (result.status === 'ok') setIsCalculate(false);
    });
  };

  const handlePopupClarifications = (member) => {
    setCurrentMember(member);
    openClarification();
  };

  const handleCloseClarification = () => {
    closeClarification();
  };

  return (
    <div key={`itemSubOrder-${product.subOrderName}`}>
      <div className={styles.ServiceSelect__subOrder}>
        <div className={cn(styles.ServiceSelect__option_content, { [styles.SubOrder__initialCursor]: type === TYPE.Service })}>
          {isLoading && isCalculate && (
            <div className={styles.ServiceSelect__calcMemberRow}>
              <FontAwesomeIcon icon={faSpinner} spinPulse size='lg' style={{ color: 'var(--color-link-violet)' }} />
              <div className={styles.ServiceSelect__calcMemberRow}>
                {product.subOrderName}
              </div>
            </div>
          )}
          {(product.membersCount === 1 && !isCalculate) && (
            <>
              {type === TYPE.ServiceSelect && (
                <Checkbox
                  label={product.subOrderName}
                  id={`id-option-checkbox-${product.subOfferId}`}
                  name={`option-checkbox-${product.subOfferId}`}
                  type='checkbox'
                  checked={product.activeMembersIds.length === 1}
                  onClick={
                    () => {
                      if (!product.isEditable) return;
                      const selectMember = product.activeMembersIds.length === 0 ? [product.members[0].member_id] : [];
                      handleCheckSubOrder(product, selectMember);
                    }
                  }
                />
              )}
              {type === TYPE.Service && (
                <Checkbox
                  className={styles.SubOrder__initialCursor}
                  label={product.subOrderName}
                  id={`id-option-checkbox-${product.subOfferId}`}
                  name={`option-checkbox-${product.subOfferId}`}
                  type='checkbox'
                  checked
                />
              )}
              <div className={styles.ServiceSelect__price}>
                {product?.cost === 0 && (
                  <>
                    <div className={styles.ServiceSelect__cost}>{numberToMoneyWithoutCurrency(product.members[0].cost)}</div>
                    <div className={styles.ServiceSelect__currency}>{product.currencySign}</div>
                  </>
                )}
                {product?.cost > 0 && (
                  <>
                    <div className={styles.ServiceSelect__cost}>{numberToMoneyWithoutCurrency(product.cost)}</div>
                    <div className={styles.ServiceSelect__currency}>{product.currencySign}</div>
                  </>
                )}
              </div>
            </>
          )}
          {(product.membersCount > 1 && !isCalculate) && (
            <>
              {type === TYPE.ServiceSelect && (
                <div
                  className={styles.ServiceSelect__option_plus}
                  onClick={() => !product.isEditable ? null : handlePopupSubOrder(product)}
                >
                  <IconSvg icon={ServicesAddIcon} />
                  {product.subOrderName}
                </div>
              )}
              {type === TYPE.Service && (
                <Checkbox
                  label={product.subOrderName}
                  id={`id-option-checkbox-${product.subOfferId}`}
                  name={`option-checkbox-${product.subOfferId}`}
                  type='checkbox'
                  checked
                />
              )}
              {product?.cost > 0 && (
                <div className={styles.ServiceSelect__price}>
                  {product?.cost > 0 && (
                    <>
                      <div className={styles.ServiceSelect__cost}>{numberToMoneyWithoutCurrency(product?.cost)}</div>
                      <div className={styles.ServiceSelect__currency}>{product.currencySign}</div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {error && (
          <div className={styles.ServiceSelect__error}>
            {error}
          </div>
        )}
      </div>

      {product.membersCount === 1 && !isCalculate && (
        <>
          {product.activeMembersIds.length === 1
            && product.members[0]?.clarifications?.length > 0
            && (type === TYPE.Service || type === TYPE.ServiceSelect)
            && (
            <div className={styles.ServiceSelect__member_row}>
              <div className={styles.ServiceSelect__clarification_row}>
                <Action
                  themes={['Link']}
                  onClick={() => handlePopupClarifications(product.members[0])}
                >
                  Уточнить
                </Action>
                {!product.members[0]?.clarifications_check && <IconSvg icon={BecomePartnerIcon} />}
              </div>
            </div>
            )
          }
          {type === TYPE.Service && (
            <div className={styles.ServiceSelect__member_row}>
              {product.isEditable && (
                <Action
                  themes={['Button', 'ButtonSmall', 'ButtonSecondary']}
                  onClick={
                    () => {
                      const selectMember = product.activeMembersIds.length === 0 ? [product.members[0].member_id] : [];
                      handleCheckSubOrder(product, selectMember);
                    }
                  }
                >
                  Удалить
                </Action>
              )}
            </div>
          )}
        </>
      )}

      {product.membersCount > 1 && !isCalculate && (
        <div className={styles.ServiceSelect__members}>
          {product.members.filter(f => product?.activeMembersIds.includes(f.member_id)).map(member => (
            <>
              <div className={styles.ServiceSelect__member_row}>
                <div className={styles.ServiceSelect__member_row_name}>{member?.member_name}</div>
                <div className={styles.ServiceSelect__member_row_price}>
                  {product?.cost > 0 && (
                    <>
                      <div className={styles.ServiceSelect__cost1}>{numberToMoneyWithoutCurrency(member?.cost)}</div>
                      <div className={styles.ServiceSelect__currency}>{product.currencySign}</div>
                    </>
                  )}
                </div>
              </div>
              {(member?.clarifications?.length > 0 && (type === TYPE.Service || type === TYPE.ServiceSelect)) && (
                <div className={styles.ServiceSelect__member_row}>
                  <div className={styles.ServiceSelect__clarification_row}>
                    <Action
                      themes={['Link']}
                      onClick={() => handlePopupClarifications(member)}
                    >
                      Уточнить
                    </Action>
                    {!member?.clarifications_check && <IconSvg icon={BecomePartnerIcon} />}
                  </div>
                </div>
              )}
            </>
          ))}
          {(product?.activeMembersIds?.length > 0 && !isCalculate) && (type === TYPE.Service || type === TYPE.ServiceSelect) && (
            <div className={styles.ServiceSelect__member_row}>
              {product.isEditable && (
                <Action
                  themes={['Button', 'ButtonSmall', 'ButtonSecondary']}
                  onClick={() => handlePopupSubOrder(product)}
                >
                  Изменить
                </Action>
              )}
            </div>
          )}
        </div>
      )}

      <Popup
        show={isPeopleSelect}
        onClose={closePeopleSelect}
        variant='small'
        header={(<div className={styles.ServiceSelect__peopleSelectHeader}>{subOrder.subOrderName}</div>)}
      >
        <div className={styles.ServiceSelect__desc}>
          <SubOrderPeopleSelect nodes={subOrder} onClose={handleClosePeopleSelect} />
        </div>
      </Popup>

      <Popup
        show={isClarification}
        onClose={closeClarification}
        variant='small'
        header={(<div className={styles.ServiceSelect__peopleSelectHeader}>{`Уточнения ${currentMember?.member_name}`}</div>)}
      >
        <div className={styles.ServiceSelect__desc}>
          <Clarifications member={currentMember} onClose={handleCloseClarification} />
        </div>
      </Popup>
    </div>
  );
};

SubOrder.propTypes = {
  product: PropTypes.shape({
    subOrderName: PropTypes.string,
    subOfferId: PropTypes.number,
    activeMembersIds: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  type: PropTypes.string
};

SubOrder.defaultProps = {
  type: 'ServiceSelect'
};

export default SubOrder;
