import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormsRow from '../../FormsRow';
import TextInput from '../../TextInput';
import DateTextInput from '../../DateTextInput';
import PhoneTextInput from '../../PhoneTextInput/PhoneTextInput';
// import DatePickerInput from '../../DatePickerInput/DatePickerInput';
import Select from '../../Select';
import Banner from '../../Banner';
import FormElementLabel from '../../FormElementLabel';
import SocialButtons from '../SocialButtons';

import { defaultDateFormat } from '../../../../../helpers/dates';
import { fetchCurrentUser } from '../../../../../services/personal';
import { nullableString } from '../../../../../helpers/yupValidation';

import * as styles from './PersonalForm.module.scss';

const gender = (
  <>
    <option value='man'>Мужской</option>
    <option value='woman'>Женский</option>
  </>
);

const MIN_LENGTH = 2;
const MAX_LENGTH = 50;

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .transform(nullableString)
    .min(MIN_LENGTH, `Должно быть больше ${MIN_LENGTH} символов`)
    .max(MAX_LENGTH, `Должно быть меньше ${MAX_LENGTH} символов`)
    .nullable(),
  // .required('Заполни ИМЯ'),
  surname: Yup.string()
    .trim()
    .transform(nullableString)
    .min(MIN_LENGTH, `Должно быть больше ${MIN_LENGTH} символов`)
    .max(MAX_LENGTH, `Должно быть меньше ${MAX_LENGTH} символов`)
    .nullable(),
  // name: Yup.string()
  //   .required('Введите имя')
  // surname: Yup.string()
  //   .required('Введите фамилию')
  //   .min(MIN_LENGTH, `Должно быть больше ${MIN_LENGTH} символов`)
  //   .max(MAX_LENGTH, `Должно быть меньше ${MAX_LENGTH} символов`),
  // phone: Yup.string()
  //   .length(15, 'Неверный номер телефона')
  //   .required('Введите номер телефона'),
  email: Yup.string()
    .email('Должен быть корректный email')
    .max(255),
  birthday: Yup.date()
    .transform(defaultDateFormat)
    .max(new Date(), 'Ты не можешь родиться в будущем')
    .typeError('Заполни дату рождения')
});

const PersonalForm = ({ formId, onSubmit }) => {
  const {
    register,
    watch,
    setValue,
    setError,
    // control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema)
  });

  const [vk, setVk] = useState(false);
  const [fb, setFb] = useState(false);
  const [phone, setPhone] = useState('');
  const birthday = watch('birthday');
  const sex = watch('sex');

  const callbackErrors = (cerrors) => {
    console.log('PersonalForm => ', cerrors);
    Object.entries(cerrors).forEach(([key, value]) => {
      setError(key, { message: value });
    });
  };

  useEffect(() => {
    fetchCurrentUser(formId, (result) => {
      setPhone(result.user.phone);
      setValue('birthday', result.user.birthday);
      setValue('sex', result.user.sex);
      setValue('email', result.user.email);
      setVk(result.user.vk);
      setFb(result.user.fb);
      setValue('surname', result.user.surname);
      setValue('name', result.user.name);
    });
  }, []);

  return (
    <form
      className={styles.PersonalForm}
      onSubmit={handleSubmit(data => onSubmit(data, formId, cerrors => callbackErrors(cerrors)))}
      id={formId}
    >
      <FormsRow>
        <PhoneTextInput
          labelText='Номер телефона'
          disabled
          value={phone}
        />
      </FormsRow>

      <FormsRow>
        <Banner noShadow />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Email'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('email')}
          error={errors.email?.message}
        />
      </FormsRow>

      <FormsRow>
        <FormElementLabel>Авторизация через приложения</FormElementLabel>
        <SocialButtons vkProfile={vk} fbProfile={fb} igProfile={false} />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Фамилия'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('surname')}
          error={errors.surname?.message}
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Имя'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('name')}
          error={errors.name?.message}
        />
      </FormsRow>

      <FormsRow>
        <DateTextInput
          labelText='Дата рождения'
          // eslint-disable-next-line react/jsx-props-no-spreading
          // {...register('birthday')}
          {...register('birthday')}
          error={errors.birthday?.message}
          value={birthday}
        />
      </FormsRow>

      <FormsRow>
        <Select
          labelText='Пол'
          name='user-sex'
          {...register('sex')}
          error={errors.sex?.message}
          value={sex}
        >
          {gender}
        </Select>
      </FormsRow>

      {/* <FormsRow>
        <DatePickerInput
          control={control}
          name='birthday'
          labelText='Дата рождения'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('birthday')}
          error={errors.birthday?.message}
          value={birthday}
        />
      </FormsRow> */}
    </form>
  );
};

PersonalForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default PersonalForm;
